import { PHASES } from '../model/phases';
import Lottie from 'react-lottie';
import LogoAnim from '../../uber-assets/eu-roty-logo-anim.json';

export function AboutHeader({scrollToSection, phase}) {
    return (
        <header className="section-container header-container" id="about">
            <div className="header-img-area">
                {/* <img className='header-img' src={HeaderImg} alt="Uber Eats Restaurant of the Year Logo" /> */}
                
                    <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: LogoAnim,
                            rendererSettings: {
                                preserveAspectRatio: 'xMidYMid slice'
                            }
                        }}
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '100%',
                            maxWidth: '100%',
                            height: 'auto',
                            zIndex: 1,
                        }}
                    />
                
            </div>
            <div className="header-split">
                <div className="content">
                    {(phase === PHASES.VOTE || phase === PHASES.VOTE_ENDED) &&
                        <>
                            <h1>Who will be 2024's Restaurant of the Year?</h1>
                            <p>We're back again this year with the 130 best restaurants on Uber Eats across the UK and Ireland, where the winning restaurant will receive a cash prize of £100,000. All runners-up will receive a cash prize to help grow their business and a marketing support package.</p>
                            <p>The final judging day takes place later this year, with a best-in-class judging line up.</p>
                            <p>Vote for your favourite restaurant below for them to be in with a chance of winning!</p>
                            {phase === PHASES.VOTE &&
                                <button className='black' onClick={() => scrollToSection('roty')}>Vote Now</button>
                            }
                        </>
                    }
                    {phase === PHASES.FINALISTS &&
                        <>
                            <h1>Congratulations to our Regional Finalists</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                            <p className='extra-bottom-pad'>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                        </>
                    }
                    {phase === PHASES.WINNERS &&
                        <>
                            <h1>Announcing our winners 2024!</h1>
                            <p className='extra-bottom-pad'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                        </>
                    }
                </div>
            </div>
        </header>
    )
}
