export function GetNomineesByCategory(category) {
    return NOMINEES.filter(nominee => nominee.category === category);
}

const WINNERS = [1, 2, 3, 4];

export const GetWinners = () => {
    return NOMINEES.filter(nominee => WINNERS.includes(nominee.id));
}

export const NOMINEES = [
    {
        id: 1,
        name: 'Nominee 1',
        category: 'female_chef',
        restaurant: 'Restaurant 1',
        location: 'Walsall',
        instagram: 'restaurant1',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam, sapien nec tincidunt fermentum, mi purus tincidunt sem, nec tincidunt ex nunc ut nisl.',
        image: 'restaurantPlaceholder.jpg', // 'placeholder-trailblazers/nom1.jpg', // Supports Carousels: ['placeholder-trailblazers/nom1.jpg', 'placeholder-trailblazers/nom2.jpg', 'placeholder-trailblazers/nom3.jpg'],
        winImage: '2023-winners/female-chef-2023.jpg',
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/',
    },
    // I need 15 more placeholder nominees
    {
        id: 2,
        name: 'Nominee 2',
        category: 'sustainable_restaurant',
        restaurant: 'Restaurant 2',
        location: 'Walsall',
        instagram: 'restaurant2',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam, sapien nec tincidunt fermentum, mi purus tincidunt sem, nec tincidunt ex nunc ut nisl.',
        image: 'restaurantPlaceholder.jpg', // 'placeholder-trailblazers/nom2.jpg',
        winImage: '2023-winners/sustainable-2023.jpg',
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/',
    },
    {
        id: 3,
        name: 'Nominee 3',
        category: 'social_impact',
        restaurant: 'Restaurant 3',
        location: 'Walsall',
        instagram: 'restaurant3',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam, sapien nec tincidunt fermentum, mi purus tincidunt sem, nec tincidunt ex nunc ut nisl.',
        image: 'restaurantPlaceholder.jpg', // 'placeholder-trailblazers/nom3.jpg',
        winImage: '2023-winners/community-2023.jpg',
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/',
    },
    {
        id: 4,
        name: 'Nominee 4',
        category: 'health_focused',
        restaurant: 'Restaurant 4',
        location: 'Walsall',
        instagram: 'restaurant4',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam, sapien nec tincidunt fermentum, mi purus tincidunt sem, nec tincidunt ex nunc ut nisl.',
        image: 'restaurantPlaceholder.jpg', // 'placeholder-trailblazers/nom4.jpg',
        winImage: '2023-winners/health-2023.jpg',
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/',
    },
    {
        id: 5,
        name: 'Nominee 5',
        category: 'female_chef',
        restaurant: 'Restaurant 5',
        location: 'Walsall',
        instagram: 'restaurant5',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam, sapien nec tincidunt fermentum, mi purus tincidunt sem, nec tincidunt ex nunc ut nisl.',
        image: 'restaurantPlaceholder.jpg', // 'placeholder-trailblazers/nom5.jpg',
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/',
    },
    {
        id: 6,
        name: 'Nominee 6',
        category: 'sustainable_restaurant',
        restaurant: 'Restaurant 6',
        location: 'Walsall',
        instagram: 'restaurant6',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam, sapien nec tincidunt fermentum, mi purus tincidunt sem, nec tincidunt ex nunc ut nisl.',
        image: 'restaurantPlaceholder.jpg', // 'placeholder-trailblazers/nom1.jpg',
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/',
    },
    {
        id: 7,
        name: 'Nominee 7',
        category: 'social_impact',
        restaurant: 'Restaurant 7',
        location: 'Walsall',
        instagram: 'restaurant7',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam, sapien nec tincidunt fermentum, mi purus tincidunt sem, nec tincidunt ex nunc ut nisl.',
        image: 'restaurantPlaceholder.jpg', // 'placeholder-trailblazers/nom2.jpg',
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/',
    },
    {
        id: 8,
        name: 'Nominee 8',
        category: 'health_focused',
        restaurant: 'Restaurant 8',
        location: 'Walsall',
        instagram: 'restaurant8',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam, sapien nec tincidunt fermentum, mi purus tincidunt sem, nec tincidunt ex nunc ut nisl.',
        image: 'restaurantPlaceholder.jpg', // 'placeholder-trailblazers/nom3.jpg',
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/',
    },
    {
        id: 9,
        name: 'Nominee 9',
        category: 'female_chef',
        restaurant: 'Restaurant 9',
        location: 'Walsall',
        instagram: 'restaurant9',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam, sapien nec tincidunt fermentum, mi purus tincidunt sem, nec tincidunt ex nunc ut nisl.',
        image: 'restaurantPlaceholder.jpg', // 'placeholder-trailblazers/nom4.jpg',
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/',
    },
    {
        id: 10,
        name: 'Nominee 10',
        category: 'sustainable_restaurant',
        restaurant: 'Restaurant 10',
        location: 'Walsall',
        instagram: 'restaurant10',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam, sapien nec tincidunt fermentum, mi purus tincidunt sem, nec tincidunt ex nunc ut nisl.',
        image: 'restaurantPlaceholder.jpg', // 'placeholder-trailblazers/nom5.jpg',
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/',
    },
    {
        id: 11,
        name: 'Nominee 11',
        category: 'social_impact',
        restaurant: 'Restaurant 11',
        location: 'Walsall',
        instagram: 'restaurant11',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam, sapien nec tincidunt fermentum, mi purus tincidunt sem, nec tincidunt ex nunc ut nisl.',
        image: 'restaurantPlaceholder.jpg', // 'placeholder-trailblazers/nom1.jpg',
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/',
    },
    {
        id: 12,
        name: 'Nominee 12',
        category: 'health_focused',
        restaurant: 'Restaurant 12',
        location: 'Walsall',
        instagram: 'restaurant12',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam, sapien nec tincidunt fermentum, mi purus tincidunt sem, nec tincidunt ex nunc ut nisl.',
        image: 'restaurantPlaceholder.jpg', // 'placeholder-trailblazers/nom2.jpg',
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/',
    },
    {
        id: 13,
        name: 'Nominee 13',
        category: 'female_chef',
        restaurant: 'Restaurant 13',
        location: 'Walsall',
        instagram: 'restaurant13',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam, sapien nec tincidunt fermentum, mi purus tincidunt sem, nec tincidunt ex nunc ut nisl.',
        image: 'restaurantPlaceholder.jpg', // 'placeholder-trailblazers/nom3.jpg',
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/',
    },
    {
        id: 14,
        name: 'Nominee 14',
        category: 'sustainable_restaurant',
        restaurant: 'Restaurant 14',
        location: 'Walsall',
        instagram: 'restaurant14',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam, sapien nec tincidunt fermentum, mi purus tincidunt sem, nec tincidunt ex nunc ut nisl.',
        image: 'restaurantPlaceholder.jpg', // 'placeholder-trailblazers/nom4.jpg',
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/',
    },
    {
        id: 15,
        name: 'Nominee 15',
        category: 'social_impact',
        restaurant: 'Restaurant 15',
        location: 'Walsall',
        instagram: 'restaurant15',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam, sapien nec tincidunt fermentum, mi purus tincidunt sem, nec tincidunt ex nunc ut nisl.',
        image: 'restaurantPlaceholder.jpg', // 'placeholder-trailblazers/nom5.jpg',
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/',
    },
    {
        id: 16,
        name: 'Nominee 16',
        category: 'health_focused',
        restaurant: 'Restaurant 16',
        location: 'Walsall',
        instagram: 'restaurant16',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam, sapien nec tincidunt fermentum, mi purus tincidunt sem, nec tincidunt ex nunc ut nisl.',
        image: 'restaurantPlaceholder.jpg', // 'placeholder-trailblazers/nom1.jpg',
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/',
    },
]

export function findNomineeData(search) {
    search = search.toLowerCase();
    // replace - with '' in the search string
    search = search.replace(/-/g, '');
    for (const nominee of NOMINEES) {
        const n = nominee.name.toLowerCase().replace(/ /g, '');
        const r = nominee.restaurant.toLowerCase().replace(/ /g, '');
        if (nominee.name.toLowerCase() === search || n === search || r === search || nominee.instagram === search || nominee.id === parseInt(search)) {
            return nominee;
        }
        if (nominee.match) {
            if (nominee.match === search) {
                return nominee;
            }
            if (Array.isArray(nominee.match)) {
                if (nominee.match.includes(search)) {
                    return nominee;
                }
            }
        }
    }
    // Not found
    return null;
}
