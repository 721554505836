import { useEffect, useState } from 'react';
import ROTYTrophy from '../../uber-assets/small-trophy-logo.svg';
import { AREAS_BUTTON_ORDER, DEFAULT_REGION_ID } from '../model/areas';
import { GetFinalists, GetRestaurantsByRegion, GetWinners } from '../model/restaurants';
import { RestaurantCard } from './restaurant-card/restaurant-card';
import nl2br from 'react-nl2br';
import { PHASES } from '../model/phases';
import { WinnerCard } from './winner-card/winner-card';
import { ROTY_WINNER_ICON } from '../../uber-assets/inline-svgs';

import ROTYBadgeAnim from '../../uber-assets/roty-badge.json';
import Lottie from 'react-lottie';

export function ROTYSection({showRestaurantInfo, instagramFunction, orderFunction, voteFunction, phase, votes, initialRegion, cardTransitionInFunction, cardTransitionOutFunction}) {
    const [activeRegion, setActiveRegion] = useState(initialRegion || DEFAULT_REGION_ID);
    const [nextRegion, setNextRegion] = useState(null);
    const [switchingRegion, setSwitchingRegion] = useState(false);
    const switchRegion = (regionId) => {
        if (!switchingRegion) {
            const switchRegionNow = () => {
                setActiveRegion(regionId);
                setSwitchingRegion(false);
                setTimeout(() => {
                    cardTransitionInFunction('restaurant-card');
                }, 100);
            }

            setSwitchingRegion(true);
            setNextRegion(regionId);
            cardTransitionOutFunction('restaurant-card', switchRegionNow)
        }
    }

    const showFinalists = phase === PHASES.FINALISTS || phase === PHASES.WINNERS;
    const showWinner = phase === PHASES.WINNERS;

    const [regionRestaurants, setRegionRestaurants] = useState([]);
    useEffect(() => {
        // Fetch restaurants for active region
        setRegionRestaurants(showFinalists ? GetFinalists() : GetRestaurantsByRegion(activeRegion));
    }, [activeRegion, phase]);

    useEffect(() => {
        if (initialRegion) {
            setActiveRegion(initialRegion);
        }
    }, [initialRegion]);

    return (
        <section className="roty-section" id="roty">
            <div className="section-container">
                <div className='content'>
                    <div className="column roty-section-heading">
                        {!showFinalists &&
                            <>
                                <div className="section-image-heading-container">
                                    {/* <img className='section-heading-image' src={ROTYTrophy} alt="Restaurant of the Year" /> */}
                                    <div className='section-heading-image'>
                                        <Lottie
                                            options={{
                                                loop: true,
                                                autoplay: true,
                                                animationData: ROTYBadgeAnim,
                                                rendererSettings: {
                                                    preserveAspectRatio: 'xMidYMid slice'
                                                }
                                            }}
                                            style={{
                                                width: '100%',
                                                height: 'auto',
                                                zIndex: 1,
                                            }}
                                        />
                                    </div>
                                    <h1>Restaurant of the Year</h1>
                                </div>
                                <p>Find below our 130 Finalists from 12 Regions within the UK and Ireland, all eligible to become the 2024 Uber Eats Restaurant of the Year.</p>

                                <div className='cat-buttons-container'>
                                    {AREAS_BUTTON_ORDER.map((area, index) => {
                                        return (
                                            <button className={`cat-button region${((area.area.id === activeRegion && !switchingRegion) || (area.area.id === nextRegion && switchingRegion)) ? ' active' : ''}`} key={index} onClick={(e) => switchRegion(area.area.id)} disabled={area.area.id === activeRegion}>{nl2br(area.label)}</button>
                                        )
                                    })}
                                </div>
                                <select className='cat-drop-down' onChange={(e) => switchRegion(e.target.value)} value={activeRegion}>
                                    {AREAS_BUTTON_ORDER.map((area, index) => {
                                        return (
                                            <option key={index} value={area.area.id}>{area.label}</option>
                                        )
                                    })}
                                </select>
                            </>
                        }
                        {showFinalists && !showWinner &&
                            <>
                                <div className="section-image-heading-container">
                                    {/* <img className='section-heading-image' src={ROTYTrophy} alt="Restaurant of the Year" /> */}
                                    <div className='section-heading-image'>
                                        <Lottie
                                            options={{
                                                loop: true,
                                                autoplay: true,
                                                animationData: ROTYBadgeAnim,
                                                rendererSettings: {
                                                    preserveAspectRatio: 'xMidYMid slice'
                                                }
                                            }}
                                            style={{
                                                width: '100%',
                                                height: 'auto',
                                                zIndex: 1,
                                            }}
                                        />
                                    </div>
                                    <h1>Restaurant of the Year Finalists</h1>
                                </div>
                                <p className='extra-bottom-pad'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                            </>
                        }
                        {showWinner &&
                            <>
                                <div className="section-image-heading-container">
                                    {/* <img className='section-heading-image' src={ROTYTrophy} alt="Restaurant of the Year" /> */}
                                    <div className='section-heading-image'>
                                        <Lottie
                                            options={{
                                                loop: true,
                                                autoplay: true,
                                                animationData: ROTYBadgeAnim,
                                                rendererSettings: {
                                                    preserveAspectRatio: 'xMidYMid slice'
                                                }
                                            }}
                                            style={{
                                                width: '100%',
                                                height: 'auto',
                                                zIndex: 1,
                                            }}
                                        />
                                    </div>
                                    <h1>Restaurant of the Year 2024</h1>
                                </div>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                                {GetWinners().map((winner, index) => {
                                    return (
                                        <WinnerCard key={index} icon={ROTY_WINNER_ICON} category="Restaurant of the Year 2024" winner={winner} infoFunction={showRestaurantInfo} instagramFunction={instagramFunction} orderFunction={orderFunction} />
                                    )
                                })}
                                <h3 className='extra-bottom-pad'>Congratulations to all of our Uber Eats Restaurant of the Year 2024 finalists...</h3>
                            </>
                        }
                    </div>
                </div>
                <div className="cards-container">
                    {regionRestaurants.map((restaurant, index) => {
                        return (
                            <RestaurantCard key={index} restaurant={restaurant} infoFunction={showRestaurantInfo} instagramFunction={instagramFunction} orderFunction={orderFunction} voteFunction={voteFunction} phase={phase} alreadyVoted={votes.indexOf(restaurant.id) !== -1} finalist={showFinalists} />
                        )
                    })}
                </div>
            </div>
        </section>
    );
}