import { useRef, useState } from 'react';
import Logo from '../../uber-assets/top-nav-ubereats-logo.svg';
import { Footer, FooterContent } from './footer';



export function Nav({scrollToSection}) {
    const menuTimeout = useRef(null);
    const [renderMenu, setRenderMenu] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const toggleMenu = (forcedState) => {
        if (forcedState === undefined) {
            forcedState = !menuActive;
        } else {
            if (forcedState === menuActive) {
                return;
            }
        }
        clearTimeout(menuTimeout.current);
        if (forcedState) {
            setRenderMenu(true);
            menuTimeout.current = setTimeout(() => {
                setMenuActive(true);
            }, 10);
            window.document.body.style.overflowY = 'hidden';
        } else {
            menuTimeout.current = setTimeout(() => {
                setRenderMenu(false);
            }, 400);
            setMenuActive(false);
            window.document.body.style.overflowY = 'auto';
        }
    }

    const scrollToSectionMob = (section) => {
        scrollToSection(section, true);
        toggleMenu(false);
    }

    return (
        <nav>
            <div className="burger-menu" onClick={() => toggleMenu()}></div>
            <img className="nav-logo" src={Logo} alt="Uber Eats Restaurant of the Year Logo" />
            <div className='grow'></div>
            <div className='nav-links'>
                <div className='nav-link' onClick={() => scrollToSection('top')}>About</div>
                <div className='nav-link' onClick={() => scrollToSection('roty')}>Restaurant of the Year</div>
                {/* <div className='nav-link' onClick={() => scrollToSection('trailblazers')}>Trailblazers Awards</div> */}
                <div className='nav-link' onClick={() => scrollToSection('prevWinners')}>2023 Winners</div>
                {/* <div className='nav-link' onClick={() => scrollToSection('faq')}>FAQs</div> */}
            </div>
            {renderMenu &&
                <>
                    <div className={`mob-blackout${menuActive ? ' active' : ''}`} onClick={() => toggleMenu(false)}></div>
                    <div className={`mob-menu${menuActive ? ' active' : ''}`}>
                        <button className='nav black' onClick={() => scrollToSectionMob('top')}>About</button>
                        <button className='nav black' onClick={() => scrollToSectionMob('roty')}>Restaurant of the Year</button>
                        {/* <button className='nav black' onClick={() => scrollToSectionMob('trailblazers')}>Trailblazers Awards</button> */}
                        <button className='nav black' onClick={() => scrollToSectionMob('prevWinners')}>2023 Winners</button>
                        {/* <button className='nav black' onClick={() => scrollToSectionMob('faq')}>FAQs</button> */}
                        <div className='grow'></div>
                        <div className='mob-menu-bottom'>
                            <FooterContent isInMenu={true} />
                        </div>
                    </div>
                </>
            }
        </nav>
    );
}
